import React, { ReactElement } from 'react'

import Template from '@/components/app/Template'
import { getPage } from '@/themes'
import { CategoryPageProps } from '@/types/pages/category'

/**
 * Category page
 */
export default function Category(props: CategoryPageProps): ReactElement {
  const CategoryPage = getPage<CategoryPageProps>(`Category`)
  return (
    <Template {...props}>
      <CategoryPage {...props} />
    </Template>
  )
}
